import React, { useEffect, useRef, useState } from 'react';
import DropDownModal from './Dropdown';

const DropdownModal = ({ header, data, className, setDropdownVal, property, label, index, width }) => {
  const kababIconRef = useRef();
  const [showDp, setShowDp] = useState(false);
  const [activeSelection, setActiveSelection] = useState(data[0]);
  const handleClick = (item) => {
    setActiveSelection(item);
    setDropdownVal(item, property);
  };

  useEffect(() => {
    if (index > -1 && !activeSelection) {
      setActiveSelection(data[index]);
    }
  }, [data, index, setActiveSelection, activeSelection]);

  return (
    <DropDownModal
      width={width}
      noContainerPadding={true}
      headerLabel={activeSelection?.label || header}
      kababIconRef={kababIconRef}
      isActive={showDp}
      setModalIsVisibal={setShowDp}
      handleClick={handleClick}
      data={data}
      singleSelection
      className={className}
      label={label}
      activeItem={activeSelection}
    />
  );
};

export default DropdownModal;
