import Routes from './Routes/Routes';
import { GlobalStyles } from './globalStyles/globalStyles';
import './App.css';

function App() {
  return (
    <div className='App'>
      <GlobalStyles />
      <Routes />
    </div>
  );
}

export default App;
