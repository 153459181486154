import styled from 'styled-components';
import { device, minDevice } from '../../constants';
import { fontFamily } from '../../constants/font';
import { FlexContainer } from '../../globalStyles';

export const CompaniesCap = styled(FlexContainer)`
  align-items: center;
  padding: 1.5rem 0rem;
`;

export const Heading = styled.span`
  font-size: 3.6rem;
  font-family: ${fontFamily.butlerBold};
  color: #000000;
  margin-left: 1.5rem;
`;

export const Container = styled.div`
  width: 90%;
  box-sizing: border-box;
  border: 0.5px solid #a0a0a0;
`;

export const Text = styled.span`
  color: #707070;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: ${fontFamily.interVariableFont};
  margin-right: 1.2rem;
  &.label {
    font-size: 2.2rem;
  }

  &.anchor {
    font-size: 2.2rem;
    color: #ae0826;
    text-decoration-line: underline;
  }

  &.iconWrapper {
    width: 5rem;
    @media ${device.desktop} and ${minDevice.mobileL} {
      width: 3rem;
    }
  }
`;

export const TableContainer = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;
