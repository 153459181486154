import styled from 'styled-components';
import { device, minDevice } from '../../constants';
import { fontFamily } from '../../constants/font';
import { FlexContainer } from '../../globalStyles';

export const Container = styled.div`
  width: 90%;
  min-height: 35rem;
  border: 0.5px solid #a0a0a0;

  &.flexDiv {
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ValuesDiv = styled(FlexContainer)`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 2rem;
  @media ${device.desktop} and ${minDevice.laptopXL} {
    width: 80%;
  }
  // 1920px - 1439px
  @media ${device.laptopXL} and ${minDevice.laptopL} {
    width: 80%;
  }
  // 1440px - 767px
  @media ${device.laptopL} and ${minDevice.tablet} {
    width: 80%;
  }
  //768px-424px
  @media ${device.tablet} and ${minDevice.mobileL} {
    width: 90%;
  }
  //425px-320px
  @media ${device.mobileL} and ${minDevice.mobileS} {
    width: 90%;
  }
`;

export const HeadingText = styled.span`
  font-size: 3.6rem;
  font-family: ${fontFamily.butlerBold};
  color: #000000;
  letter-spacing: 1.65px;
  display: block;
  > span {
    font-size: 3.3rem;
  }

  &.chartHeading {
    font-size: 3.6rem;
    letter-spacing: 1.8px;
  }

  &.values > span {
    @media ${device.desktop} and ${minDevice.laptopXL} {
      font-size: 3.5rem;
    }
    // 1920px - 1439px
    @media ${device.laptopXL} and ${minDevice.laptopL} {
      font-size: 3.3rem;
    }
    // 1440px - 767px
    @media ${device.laptopL} and ${minDevice.tablet} {
      font-size: 3rem;
    }
    //768px-424px
    @media ${device.tablet} and ${minDevice.mobileL} {
      font-size: 2.2rem;
    }
    //425px-320px
    @media ${device.mobileL} and ${minDevice.mobileS} {
      font-size: 1.6rem;
    }
  }
`;

export const ValueText = styled.span`
  font-family: ${fontFamily.segoeUiBold};
  font-size: 9rem;
  color: #ae0826;
  letter-spacing: 0.37rem;
  display: block;

  @media ${device.desktop} and ${minDevice.laptopXL} {
    font-size: 10rem;
  }
  // 1920px - 1439px
  @media ${device.laptopXL} and ${minDevice.laptopL} {
    font-size: 9rem;
  }
  // 1440px - 767px
  @media ${device.laptopL} and ${minDevice.tablet} {
    font-size: 6rem;
  }
  //768px-424px
  @media ${device.tablet} and ${minDevice.mobileL} {
    font-size: 4rem;
  }
  //425px-320px
  @media ${device.mobileL} and ${minDevice.mobileS} {
    font-size: 3rem;
  }
`;

export const Hr = styled.hr`
  color: #a0a0a0;
  height: 10rem;
`;

export const P = styled.span`
  font-size: 1.7rem;
  font-family: ${fontFamily.interVariableFont};
  letter-spacing: 0.39rem;
`;

export const Span = styled.span`
  font-size: 2.2rem;
  letter-spacing: 0.22rem;
  font-family: ${fontFamily.segoeUiSemiBold};
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &.underline {
    box-shadow: inset 0px -2px 0px 0px #ae0826;
    color: #ae0826 !important;
    font-family: ${fontFamily.interBold};
    letter-spacing: 2.2px;
  }

  &.active {
    color: #707070;
    font-family: ${fontFamily.interBold};
    text-decoration: underline;
    text-underline-offset: 0.51rem;
    position: relative;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 2.2px;

    :hover {
      color: #ae0826;
    }
  }

  &.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 250ms ease-in;
  }

  &.active:hover::after {
    transform: scaleX(1);
    color: #ae0826;
  }

  &.active.splitPoint:hover::after {
    transform-origin: center;
  }

  &.indexVal {
    font-size: 6rem;
    font-family: ${fontFamily.segoeUiBold};
    line-height: 30%;
  }
`;

export const Text = styled.p`
  color: black;
  font-size: 3rem;
  letter-spacing: 1.5px;
  font-family: ${fontFamily.segoeUiBold};
`;

export const HorizontelLine = styled.hr`
  width: 96%;
  margin-top: 1rem;
  color: #707070;
  opacity: 0.3;

  &.indexMetrix {
    display: none;
  }
`;

export const Duration = styled(FlexContainer)`
  gap: 3rem;
  margin-top: 2rem;
  margin-left: 3rem;

  &.constituents {
    gap: 3rem;
  }

  &.noDuration {
    display: none;
  }

  &.keyIndexChart {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${device.tablet} and ${minDevice.mobileS} {
      display: flex;
      margin-left: 0rem;
      flex-direction: column;
    }
  }
`;

export const Header = styled(FlexContainer)`
  &.space-between {
    width: 100%;
    justify-content: space-between;
  }
  @media ${device.mobileL} and ${minDevice.mobileS} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Wrapper = styled(FlexContainer)`
  position: relative;
  bottom: 2rem;
  &.chartWrapper {
    bottom: 0;
    top: 4rem;
    @media ${device.laptop} and ${minDevice.mobileS} {
      flex-direction: column;
    }
  }

  @media ${device.mobileL} and ${minDevice.mobileS} {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 3rem;
  }

  &.keyCloudIndex {
    @media ${device.laptop} and ${minDevice.mobileS} {
      flex-direction: column;
    }
  }
`;

export const DropDownsWrap = styled(FlexContainer)`
  margin-right: 3rem;
  flex-direction: column;
  cursor: pointer;
`;

export const Button = styled.button`
  position: relative;
  height: 5.4rem;
  width: 39rem;
  color: black;
  font-size: 1.7rem;
  letter-spacing: 3.9px;
  font-family: ${fontFamily.segoeUiSemiBold};
  letter-spacing: 2px;
  border: none;
  outline: 0;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition: background 0.58s ease-in-out;
  box-shadow: -1px -7px 0px -3px rgba(0, 0, 0, 0.95) inset;
  -webkit-box-shadow: -1px -7px 0px -3px rgba(0, 0, 0, 0.95) inset;
  -moz-box-shadow: -1px -7px 0px -3px rgba(0, 0, 0, 0.95) inset;

  :hover {
    color: white;
  }

  :before {
    content: '';
    position: absolute;
    background: black;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
  }

  :hover:before {
    top: 0;
  }
`;

export const ChartHeader = styled.div`
  margin-top: 3.6rem;
  margin-bottom: 1.5rem;
  margin-left: 3.1rem;
  width: 50%;
  @media ${device.tablet} and ${minDevice.mobileS} {
    width: 100%;
    & > .chartHeading {
      width: 100%;
      display: block;
      white-space: nowrap;
    }
  }
`;

export const ChartWrap = styled.div`
  position: relative;
  width: 96%;
  left: 2%;
  font-family: ${fontFamily.segoeUiBold};

  &.chartWrap {
    & > div > div > ul {
      width: 70vw;
      position: relative;
      left: 10vw;
      top: 2rem;
      display: flex;
      justify-content: space-around;

      @media ${device.mobileL} and ${minDevice.mobileS} {
        top: 8rem;
      }

      & > li > svg {
        display: none !important;
      }

      & > li.legend-item-0 > span {
        ::before {
          background: #104591;
        }
      }

      & > li.legend-item-1 > span {
        ::before {
          background: #315c2b;
        }
      }

      & > li.legend-item-2 > span {
        ::before {
          background: #119da4;
        }
      }

      & > li > span {
        ::before {
          display: inline-block;
          width: 20px;
          height: 10px;
          content: '';
          border-radius: 3px;
          margin-right: 5px;
          background: #ee6265;
        }
        font-size: 1.8rem;
        fontfamily: ${fontFamily.segoeUiBold} !important;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        color: #707070 !important;
      }
    }
    & svg.recharts-surface {
      overflow: visible;
    }
  }

  & div.recharts-default-tooltip {
    p.recharts-tooltip-label {
      font-size: 2rem;
    }

    > ul > li > span {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  & .recharts-cartesian-axis-ticks g text {
    font-size: 15px;
    font-family: ${fontFamily.interVariableFont};
  }
  & .recharts-cartesian-axis-ticks tspan {
    font-size: 10px;
    font-family: ${fontFamily.interVariableFont};
  }
`;

export const IndexesDiv = styled.div`
  position: relative;
  bottom: 2rem;
  text-align: center;

  & > P {
    /* text-transform: uppercase; */

    //768px-424px
    @media ${device.tablet} and ${minDevice.mobileL} {
      font-size: 2.2rem;
    }
    //425px-320px
    @media ${device.mobileL} and ${minDevice.mobileS} {
      font-size: 2rem;
    }
  }

  & > span.indexVal {
    //768px-424px
    @media ${device.tablet} and ${minDevice.mobileL} {
      font-size: 5rem;
    }
    //425px-320px
    @media ${device.mobileL} and ${minDevice.mobileS} {
      font-size: 3rem;
    }
  }
`;

export const OverviewChartDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-family: ${fontFamily.segoeUiBold};
  padding-right: 10px;
  & svg.recharts-surface {
    overflow: visible;
  }
  &.normal-size {
    & li > span {
      text-align: left;
      font-size: 1.7rem;
      font-family: ${fontFamily.interBold};
      letter-spacing: 1.7px;
      color: #707070 !important;
      opacity: 1;
    }

    & .xAxis > text.recharts-label {
      display: none;
    }

    text > tspan {
      font-size: 1.6rem;
    }

    & .yAxis > text.recharts-label {
      display: none;
    }

    & li > svg {
      display: none !important;
    }
  }

  path.recharts-tooltip-cursor {
    fill: #f4f4f4;
  }
`;

export const YAxisLabel = styled.p`
  transform: rotate(-90deg);
  font-size: 1.8rem;
  position: absolute;
  left: -12rem;
  color: #707070;
  letter-spacing: 1.7px;
  font-weight: bold;
  font-family: ${fontFamily.interBold};

  &.keybuisness {
    left: -3rem;
    position: relative;
    width: 50rem;
  }
`;

export const ToopltipP = styled.span`
  font-family: ${fontFamily.segoeUiBold};
  font-size: 1.8rem;
  font-weight: normal;
  color: #808080;
  text-transform: capitalize;

  &.labelName {
    font-size: 2rem;
    margin: 1.8rem 0rem 0rem 0rem;
  }
`;

export const BtnDiv = styled.div`
  width: 39rem;
  text-align: center;
  position: relative;
  top: 3rem;
`;

export const Activeopt = styled.span`
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  font-size: 2.2rem;
  letter-spacing: 0.22rem;
  color: #707070;
  font-family: ${fontFamily.segoeUiSemiBold};

  :disabled {
    box-shadow: inset 0px -2px 0px 0px #ae0826;
  }

  &.underline {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 2.2rem;
    color: #ae0826;
    margin-bottom: 2rem;
  }

  &.underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 250ms ease-in;
  }

  &.underline:hover::after {
    transform: scaleX(1);
  }

  &.underline.splitPoint:hover::after {
    transform-origin: center;
  }
`;
