// sort array ascending
const asc = (arr, prop) => arr.sort((a, b) => a?.[prop] - b?.[prop]);

const sum = (arr, prop) =>
  arr.reduce((a, b) => {
    return a + (b?.[prop] !== '' && b?.[prop] !== undefined ? b?.[prop] : 0);
  }, 0);

// sample standard deviation

const quartile = (sorted, q, prop) => {
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    const value = sorted[base]?.[prop] + rest * (sorted[base + 1]?.[prop] - sorted[base]?.[prop]);
    const index = sorted[base + 1]?.[prop] <= value ? base + 1 : base;
    return { index, value };
  } else {
    return { index: base, value: sorted[base]?.[prop] };
  }
};

export const getQuartiles = (arr, prop) => {
  const sorted = asc(
    arr.filter((e) => e?.[prop]), // eleminate undefined values
    prop
  );
  const { index: q1Base } = quartile(sorted, 0.25, prop);
  const { index: q3Base } = quartile(sorted, 0.75, prop);

  // size of categories
  const bottomLen = q1Base; // 0-25
  const mediumLen = q3Base - q1Base; // 25-75
  const topLen = sorted.length - q3Base; //75-100
  const totalLen = bottomLen + mediumLen + topLen;

  // sum of value at each category
  const bottomSum = sum(sorted.slice(0, q1Base), prop);
  // const { index: q2Base, value: q2 } = quartile(sorted, 0.5, prop);
  const mediumSum = sum(sorted.slice(q1Base, q3Base), prop);
  const topSum = sum(sorted.slice(q3Base), prop);
  const totalSum = bottomSum + mediumSum + topSum;
  // console.log('bottom', bottomSum, bottomLen);
  // console.log('medium', mediumSum, mediumLen);
  // console.log('top', topSum, topLen);
  //avg
  return {
    bottom: bottomSum / bottomLen,
    medium: mediumSum / mediumLen,
    top: topSum / topLen
  };

  // sum percentage
  // return {
  //   bottom: (bottomSum / totalSum) * 100,
  //   medium: (mediumSum / totalSum) * 100,
  //   top: (topSum / totalSum) * 100
  // };

  // count percentage
  // return {
  //   bottom: (bottomLen / totalLen) * 100,
  //   medium: (mediumLen / totalLen) * 100,
  //   top: (topLen / totalLen) * 100
  // };

  // count
  // return {
  //   bottom: bottomLen,
  //   medium: mediumLen,
  //   top: topLen
  // };
};

export const filterOutliers = (someArray, key) => {
  if (someArray.length < 4) {
    return someArray;
  }

  let values = someArray.slice().sort((a, b) => a[key] - b[key]); // copy array fast and sort
  let q1 = getQuantile(values, 25, key);
  let q3 = getQuantile(values, 75, key);

  let iqr, maxValue, minValue;
  iqr = q3 - q1;
  maxValue = q3 + iqr * 1.5;
  minValue = q1 - iqr * 1.5;
  return values.filter((x) => {
    const res = x?.[key] >= minValue && x?.[key] <= maxValue;
    if (!res) {
      console.log('excluding', x, key);
    }
    return res;
  });
};

function getQuantile(array, quantile, key) {
  try {
    // Get the index the quantile is at.
    let index = (quantile / 100.0) * (array.length - 1);
    // Check if it has decimal places.
    if (index % 1 === 0) {
      return array[index]?.[key];
    } else {
      // Get the lower index.
      let lowerIndex = Math.floor(index);
      // Get the remaining.
      let remainder = index - lowerIndex;
      // Add the remaining to the lowerindex value.
      return array[lowerIndex]?.[key] + remainder * (array[lowerIndex + 1]?.[key] - array[lowerIndex]?.[key]);
    }
  } catch (e) {
    console.log('error in filtering outliers', e);
  }
}
