import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import Loader from '../../Components/Loader/Loader';
import { endpoints } from '../../endpoints';
import { FlexContainer } from '../../globalStyles';
import useGraphWidthHeight from '../../shared/hooks/useGraphWidthHeight';
import { errorHandler } from '../../utility/errorHandler';
import { ChartWrap, Duration, HorizontelLine, IndexesDiv, Span, Text as HText, ToopltipP } from './Overview.style';
import { LoaderDiv } from '../Analysis/Analysis.style';
import styled from 'styled-components';

const dateFormat = 'YYYY-MM-DD';
const filters = [
  { id: 1, label: '1w', value: { unit: 'days', value: 7 } }, //0
  { id: 2, label: '1m', value: { unit: 'days', value: 30 } }, //1
  { id: 3, label: '3m', value: { unit: 'days', value: 3 * 30 } }, //2
  { id: 4, label: '1y', value: { unit: 'days', value: 365 } }, // 3
  { id: 5, label: 'max (Apr-21)', value: moment(new Date('1/4/2021')).format(dateFormat) } //4
];
const colorOption = { DIGIHLTH: '#104591', NDX: '#315C2B', DJI: '#119DA4', GSPC: '#EE6265' };
const indexes = [
  { id: 1, label: 'DIGIHLTH', key: 'DIGIHLTH' },
  { id: 2, label: 'NASDAQ', key: 'NDX' },
  { id: 3, label: 'Dow Jones', key: 'DJI' },
  { id: 4, label: 'S&P 500', key: 'GSPC' }
];

const IndexPerfChart = () => {
  const chartBoxRef = useRef();
  const [indexChange, setIndexChange] = useState([]);
  const { widthOf, heightOf } = useGraphWidthHeight(chartBoxRef);
  const [currentDuration, setCurrentDuration] = useState(filters[1]);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPlotData = (timeRange) => {
    setLoading(true);
    axios
      .post(endpoints.indexPerformace, timeRange)
      .then((res) => {
        setLoading(false);
        const { data, status } = res;
        if (status >= 200 && status < 300) {
          let prevVal = {};
          const finalData = data.data.map((e) => {
            const { DIGIHLTH, NDX, DJI, GSPC, date } = e;
            const val = {
              DIGIHLTH: prevVal.DIGIHLTH || 0,
              NDX: prevVal.NDX || 0,
              DJI: prevVal.DJI || 0,
              GSPC: prevVal.GSPC || 0,
              date
            };
            prevVal = {
              DIGIHLTH: val.DIGIHLTH + DIGIHLTH,
              NDX: val.NDX + NDX,
              DJI: val.DJI + DJI,
              GSPC: val.GSPC + GSPC
            };
            return val;
          });
          setPlotData(finalData);
          // finding the change
          const startObj = finalData[0];
          const endObj = finalData?.[finalData.length - 1];
          const change = indexes.map((e) => {
            const { key, label, id } = e;
            const changeInVal = endObj?.[key] - startObj?.[key];
            return {
              id,
              key,
              label,
              changeInVal
            };
          });
          setIndexChange(change);
        } else {
          throw res;
        }
      })
      .catch((e) => {
        setLoading(false);
        const error = errorHandler(e);
        console.log('e', e, error.error);
      });
  };
  useEffect(() => {
    handleTimeRangeChange(filters[4]);
  }, []);

  const handleTimeRangeChange = (option) => {
    setCurrentDuration(option);
    const {
      value: { unit, value }
    } = option;
    const timeRange = {
      from: unit ? moment().subtract(value, unit).format(dateFormat) : option.value,
      to: moment().subtract(1, 'days').format(dateFormat)
    };
    fetchPlotData(timeRange);
  };

  return (
    <>
      <Duration>
        {filters.map((ele, i) => (
          <Span
            className={`active splitpoint  ${currentDuration?.label === ele.label && 'underline'}`}
            onClick={() => handleTimeRangeChange(filters[i])}
            key={ele.id}
          >
            {ele.label}
          </Span>
        ))}
      </Duration>
      <HorizontelLine />
      <FlexContainer
        className='jc_center'
        style={{
          justifyContent: 'space-between',
          width: '90%',
          marginLeft: '2%',
          position: 'relative'
        }}
      >
        {indexChange?.map((ele) => {
          const { key, label, changeInVal } = ele;
          return (
            <IndexesDiv key={ele.id}>
              <HText>{label}</HText>
              <Span className='indexVal' style={{ color: `${colorOption[key]}` }}>
                {changeInVal > 0 && '+'}
                {changeInVal ? changeInVal?.toFixed(1) : '+0'}%
              </Span>
            </IndexesDiv>
          );
        })}
      </FlexContainer>

      <ChartWrap className='normal-size chartWrap' ref={chartBoxRef}>
        {loading && (
          <LoaderDiv>
            <Loader />
          </LoaderDiv>
        )}
        <LineChart width={widthOf} height={heightOf} data={plotData} margin={{ right: 15, left: 0, top: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            minTickGap={150}
            tickMargin={15}
            tick={<CustomizedAxisTick />}
            dataKey={(date) => {
              return moment(date.date).format('DD MMM YYYY');
            }}
          />
          <YAxis
            mirror
            tickFormatter={(p) => `${Number(p).toFixed(0)}%`}
            domain={['dataMin', 'dataMax']}
            type='number'
          />
          <Tooltip
            wrapperStyle={{
              outline: 'none',
              background: '#ffffff 0% 0% no-repeat padding-box',
              boxShadow: '0px 0px 25px #00000026',
              border: '0.5px solid #a0a0a0',
              padding: '5px 5px',
              minWidth: 180
            }}
            content={<CustomTooltip />}
            indexPerformace={true}
          />
          <Legend />
          {indexes.map((index) => {
            const { key, label, id } = index;
            return <Line dot={false} key={id} type='linear' name={label} dataKey={key} stroke={colorOption[key]} />;
          })}
        </LineChart>
      </ChartWrap>
    </>
  );
};

export default IndexPerfChart;

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor='middle'>{payload.value}</text>
    </g>
  );
};

function CustomTooltip({ payload, label, active, indexPerformace }) {
  if (active) {
    return (
      <div className='custom-tooltip' style={{ padding: '1rem' }}>
        {indexPerformace && (
          <FlexContainer style={{ justifyContent: 'space-between' }}>
            <ToopltipP className='labelName' style={{ color: '#AE0826' }}>
              {moment(payload?.[0]?.payload?.date).format('MMMM DD, YY')}
            </ToopltipP>
            <span style={{ width: '4rem' }}>&nbsp;&nbsp;&nbsp;</span>
            <ToopltipP className='labelName' style={{ color: 'black' }}>
              Net Change
            </ToopltipP>
          </FlexContainer>
        )}

        {payload?.map((ele) => {
          return (
            <FlexContainer key={ele.name} style={{ justifyContent: 'space-between' }}>
              <ToopltipP className='labelName'>
                <Dot bgColor={colorOption[ele.name]} />
                {ele.name}
              </ToopltipP>
              <span style={{ width: '4rem' }}>&nbsp;</span>
              <ToopltipP style={{ fontSize: '2rem', color: 'black' }} className='labelName'>
                {`${Number(ele?.value)?.toFixed(1)}%`}
              </ToopltipP>
            </FlexContainer>
          );
        })}
      </div>
    );
  }

  return null;
}

export const Dot = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'black')};
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px 2px 0px;
`;
