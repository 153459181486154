import styled from 'styled-components';
import { device, minDevice } from '../constants';
import { fontFamily } from '../constants/font';
import { FlexContainer } from '../globalStyles';

export const Li = styled.button`
  border: none;
  margin: 0 2.5% 0 2.5%;
  background: white;
  padding-bottom: 3.5rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  & > div > span.uppercase {
    font-size: 1.6rem;
    font-family: ${fontFamily.segoeUiBold};
    color: #423b36;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }

  :disabled {
    box-shadow: inset 0px -2px 0px 0px #ae0826;
  }

  & .iconColor {
    display: flex;
    justify-content: center;
  }

  &.underline {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 30px;
    color: #ae0826;
    margin-bottom: 2rem;
  }

  &.underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 250ms ease-in;
  }

  &.underline:hover::after {
    transform: scaleX(1);
  }

  &.underline.splitPoint:hover::after {
    transform-origin: center;
  }

  @media ${device.mobileL} and ${minDevice.mobileS} {
    padding-bottom: 5rem;
  }

  @media ${device.tablet} and ${minDevice.mobileL} {
    padding-bottom: 4rem;
  }
`;

export const Ui = styled(FlexContainer)`
  height: 3.1rem;
  text-align: center;
  padding: 3rem 0rem;

  @media ${device.mobileL} and ${minDevice.mobileS} {
    padding-bottom: 5rem;
  }

  @media ${device.tablet} and ${minDevice.mobileL} {
    padding-bottom: 4rem;
  }
`;

export const Span = styled.span`
  font-size: 2.2rem;
  font-family: ${fontFamily.segoeUiSemiBold};
  color: #423b36;
  text-transform: capitalize;
  letter-spacing: 1px;
  &.uppercase {
    text-transform: uppercase;
  }
`;
