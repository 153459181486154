import React from 'react';
import UnderlineHeader from '../../utility/underlineHeader';
import { ChartHeader, Container, Header, HeadingText } from './Overview.style';
import styled from 'styled-components';
import { fontFamily } from '../../constants/font';

export const Chart = (props) => {
  const { heading, noOfLastWords, ht, chart } = props;
  return (
    <Container style={{ height: ht }}>
      <Header>
        <ChartHeader>
          <HeadingText className='chartHeading'>
            <UnderlineHeader header={heading} noOfLastWords={noOfLastWords} />
          </HeadingText>
        </ChartHeader>
      </Header>
      {chart && <>{chart}</>}
    </Container>
  );
};
export default Chart;

export const DropDownLabel = styled.div`
  font-size: 1.8rem;
  font-family: ${fontFamily.segoeUiBold};
  letter-spacing: 1.8px;
  color: #707070;
  opacity: 1;
  position: relative;
  bottom: 0.5rem;
  left: 1rem;
`;
