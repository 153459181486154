import styled from 'styled-components';
import { fontFamily } from '../../constants/font';
import { FlexContainer } from '../../globalStyles';

export const DropdownLabelContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  font-family: ${fontFamily.josefinBold};
  font-size: 1.8rem;
  letter-spacing: 1.8px;
  min-height: 4.6rem;
  color: #ae0826;
  min-width: 27rem;
  border: 1px solid #384355;
  & span {
    font-size: 1.8rem;
  }

  &.company1 {
    background-color: #819fb8;
    color: #ffffff;
    border: 0.5px solid #a0a0a0;
  }

  &.company2 {
    background-color: #384355;
    color: #ffffff;
    border: 1px solid #384355;
  }
`;

export const ModalContainer = styled.div`
  min-height: 20px;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000026;
  z-index: 21;
  width: ${(props) => props.width || '100%'};
  display: flex;
  overflow: auto;
  min-width: max-content;
  max-height: 50rem;
  border: 0.5px solid #a0a0a0;
  // hanlde dropdown V1
  display: ${({ isActive }) => (isActive || isActive === undefined ? 'block' : 'none')};
  // TODO: removed
  padding: ${({ noContainerPadding }) => (noContainerPadding ? '0px' : '15px 20px 15px 20px')};
  &.outside {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000026;

    border: 0.5px solid #a0a0a0;
  }

  @keyframes heightAnimation {
    from {
      opacity: 0;
      transform: translateY(10px);
      overflow: hidden;
    }
    to {
      opacity: 1;
      transform: translateY(0px);
      overflow: hidden;
    }
  }
`;

export const NestedModalContainer = styled.div`
  padding: 10px 20px 15px 10px;
`;

export const ModalContentDiv = styled.div`
  height: 4rem;
  &.hasRadio {
    display: flex;
    justify-content: space-between;
  }
  &.icon {
    & p {
      margin-left: 10px;
    }
  }
`;

export const ModalContentText = styled.p`
  font-size: 1.7rem;
  margin-top: 10px;
  letter-spacing: 1.7px;
  cursor: pointer;
  text-transform: capitalize;
  font-family: ${fontFamily.josefinSemiBold};
  &.outside {
    color: #000000;
    letter-spacing: 0.17px;
  }
  &.settingBtn {
    display: flex;
  }
  &.settingMenu {
    display: flex;
  }
  &.disable {
    opacity: 0.2;
  }

  &.uploader {
    font-size: '1.8rem';
    color: black;
  }

  :hover {
    color: #ae0826;
    font-weight: 600;
  }
`;

export const SelectDiv = styled.div`
  width: ${(props) => props.width || '100%'};
`;
