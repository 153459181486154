import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import { endpoints } from '../../endpoints';
import { FlexContainer } from '../../globalStyles';
import { errorHandler } from '../../utility/errorHandler';
import HeadToHeadCompanrision from './HeadToHeadCompanrision';
import KeyBussinessModelMetrics from './KeyBussinessModelMetrics';

const Analysis = () => {
  const [loading, setLoading] = useState(false);
  const [plotData, setPlotData] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .post(endpoints.constituents)
      .then((res) => {
        setLoading(false);
        const { data, status } = res;
        if (status >= 200 && status < 300) {
          setPlotData(data.data);
        } else {
          throw res;
        }
      })
      .catch((e) => {
        setLoading(false);
        const error = errorHandler(e);
        console.log('e', e, error.error);
      });
  }, []);
  return (
    <FlexContainer className='ai_center col' style={{ gap: '3rem' }}>
      <KeyBussinessModelMetrics plotData={plotData ? Object.values(plotData) : []} loading={loading} />
      <HeadToHeadCompanrision plotData={plotData} loading={loading} />
      <Footer />
    </FlexContainer>
  );
};

export default Analysis;

export function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className='custom-tooltip'>
        {payload?.map((ele) => (
          <p style={{ fontSize: '2rem' }} className='label'>
            {ele.name} : {ele.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
}
