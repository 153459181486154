import { FlexContainer } from '../../globalStyles';
import Chart from './Chart';
import { BtnDiv, Button, Container, HeadingText, Hr, ValuesDiv, ValueText } from './Overview.style';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { endpoints } from '../../endpoints';
import { errorHandler } from '../../utility/errorHandler';
import UnderlineHeader from '../../utility/underlineHeader';
import Footer from '../../Components/Footer/Footer';
import IndexPerfChart from './IndexPerfChart';
import KeyCloudIndexMetric from './KeyCloudIndexMetric';

const data = [
  { id: 1, label: 'Total Market Cap', key: 'totalMarketCap', sign: 'B', preValueLabel: '$' },
  { id: 2, label: 'Avg. Revenue Multiple', key: 'avgRevenueMultiple', sign: 'x' },
  { id: 3, label: 'Avg. Growth Rate', key: 'avgGrowthRate', sign: '%' }
];

const Overview = ({ setActiveTab, tabs }) => {
  const [err, setErr] = useState('');
  const [summaryDetails, setSummaryDetails] = useState({});
  useEffect(() => {
    setErr('');
    const endPoint = endpoints.constituents;
    axios
      .post(endPoint)
      .then((res) => {
        const { data: rawData, status } = res;
        if (status >= 200 && status < 300) {
          const { data, count } = rawData;
          let revenueGrowthCount = 0;
          let evCount = 0;
          let totalMarketCap = 0;
          let totalEnterpriseToRevenue = 0;
          let totalRevenueGrowth = 0;
          Object.values(data)?.forEach((e, index) => {
            const { revenueGrowth, marketCap, enterpriseToRevenue } = e;
            // excluding null in count
            if (revenueGrowth) {
              revenueGrowthCount += 1;
            }
            if (enterpriseToRevenue) {
              evCount += 1;
            }
            totalMarketCap += Number(marketCap) || 0;
            totalRevenueGrowth += Number(revenueGrowth) || 0;
            totalEnterpriseToRevenue += Number(enterpriseToRevenue) || 0;
          });
          // console.log(totalEnterpriseToRevenue, revenueGrowthCount, totalEnterpriseToRevenue, evCount);
          setSummaryDetails({
            totalMarketCap: Number(totalMarketCap / Math.pow(10, 9)).toFixed(1), // in trillion
            avgRevenueMultiple: Number(totalEnterpriseToRevenue / revenueGrowthCount).toFixed(1),
            avgGrowthRate: Number(totalRevenueGrowth / evCount).toFixed(1)
          });
        } else {
          throw res;
        }
      })
      .catch((e) => {
        const payload = errorHandler(e);
        setErr(payload.error);
      });
  }, []);

  return (
    <FlexContainer className='ai_center col' style={{ gap: '3rem' }}>
      {/* summary */}
      <Container className='flexDiv'>
        <ValuesDiv>
          {data.map((ele, i) => {
            const { label, key, sign, preValueLabel } = ele;

            return (
              <React.Fragment key={ele.id}>
                <div style={{ textAlign: 'center' }}>
                  <HeadingText className='values'>
                    <UnderlineHeader header={label} noOfLastWords={2} />
                  </HeadingText>
                  <ValueText>
                    {!summaryDetails?.[key] ? '-' : `${preValueLabel || ''}${summaryDetails?.[key]}${sign}`}
                  </ValueText>
                </div>
                {i !== data.length - 1 && <Hr />}
              </React.Fragment>
            );
          })}
        </ValuesDiv>
        <BtnDiv>
          <Button onClick={() => setActiveTab(tabs[1])}>Dive into the index and its metrics</Button>
        </BtnDiv>
      </Container>
      {/* Index Perfomance plot*/}
      <Chart
        heading='Index Performance'
        noOfLastWords={1}
        ht='81rem'
        overview={true}
        chartType='indexPerformance'
        chart={<IndexPerfChart />}
      />
      {/* 'Key Cloud Index Metrix plot */}
      <Chart
        heading='Key DIGIHLTH Index Metrics'
        noOfLastWords={2}
        className='indexMetrix'
        chart={<KeyCloudIndexMetric />}
      />
      <Footer />
    </FlexContainer>
  );
};

export default Overview;
