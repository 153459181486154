import React from 'react';
import styled from 'styled-components';

const UnderlineHeader = ({ header, noOfLastWords }) => {
  const headerArr = header.split(' ');
  const totalLen = headerArr.length;
  const countFromLast = totalLen - noOfLastWords;
  const min = Math.min(totalLen, countFromLast);
  const firstSet = headerArr.slice(0, min).join(' ');
  const endSet = headerArr.slice(min)?.join(' ');
  return (
    <>
      <span>{firstSet} </span>
      <Span>{endSet}</Span>
    </>
  );
};

export default UnderlineHeader;

export const Span = styled.span`
  text-decoration: underline;
  text-decoration-color: #ae0826;
  text-underline-offset: 0.3em;
`;
