import React, { useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';
import backArrow from '../../assets/icons/right_arrow.svg';
import {
  DropdownLabelContainer,
  ModalContainer,
  ModalContentDiv,
  ModalContentText,
  NestedModalContainer,
  SelectDiv
} from './Dropdown.style';
import { Image } from '../../globalStyles';
import useClickOutside from '../../shared/hooks/useClickOutside';
import styled from 'styled-components';
import whiteArrow from '../../assets/icons/right_arrowwhite.svg';

function DropDownModal(props) {
  const {
    style = {},
    ContainerStyle = {},
    TextStyle = {},
    data = [],
    itemContainerStyle = {},
    setModalIsVisibal,
    kababIconRef = false,
    handleClick,
    version = 1,
    isActive,
    field = '',
    extraParms, // additional params required to pass during click
    headerLabel, // dropdown header label
    activeItem, // active selected item
    showSelection, // show radio icon indication
    label = 'label', // label key which needs to used
    iconUrl = 'iconUrl', // icon for each item ? optional
    width,
    singleSelection, // closes the dropdown on selection if true
    itemKey,
    noContainerPadding = false, // main container padding
    className
  } = props;
  const modalReff = useRef();
  const secondaryRef = useRef();
  const [modalRef, setModalRef] = useState(null);
  const [boxRef, setBoxRef] = useState(null); // default reference if refernce prop is defined

  if (boxRef !== null && modalRef !== null) {
    createPopper(boxRef, modalRef, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4]
          }
        }
      ]
    });
  }

  useClickOutside(
    modalReff,
    () => {
      if (!field) {
        return setModalIsVisibal(false);
      }
      return setModalIsVisibal(field);
    },
    secondaryRef
  );
  const handleSelection = (item) => {
    if (singleSelection) {
      setModalIsVisibal(false);
    }
    if (!field) {
      return handleClick(item, { label, activeRowItem: extraParms?.activeRowItem, itemKey });
    }
    return handleClick(field, item);
  };

  const handleModalVisibal = () => {
    setModalIsVisibal((prev) => !prev);
  };

  const header = (
    <>
      {headerLabel && (
        <DropdownLabelContainer
          isActive={isActive}
          ref={kababIconRef}
          onClick={handleModalVisibal}
          className={className}
          style={{ width: width }}
        >
          <span style={{ paddingLeft: '0.7rem' }}>{headerLabel}</span>
          <Image
            className={isActive ? 'rotate' : ''}
            rotateDeg={90}
            width='30'
            height='20'
            alt='arrow'
            src={!className ? backArrow : whiteArrow}
          />
        </DropdownLabelContainer>
      )}
    </>
  );

  return (
    <>
      <SelectDiv className='dropDown' ref={secondaryRef}>
        {header}
        <div ref={setBoxRef}>{props.children}</div>
        {(isActive || isActive === undefined) && true && (
          <ModalContainer
            isActive={isActive}
            className={version === '1' ? '' : 'outside'}
            ref={setModalRef}
            style={(ContainerStyle || style, { width: width })}
            width={width}
            noContainerPadding={noContainerPadding}
          >
            <NestedModalContainer ref={modalReff}>
              <>
                {data?.map((item, i) => (
                  <ModalContentDiv
                    // TODO: not accepting string interpolation
                    className={
                      (item.class || '') +
                      ' ' +
                      (activeItem ? 'hasRadio' : '') +
                      ' ' +
                      (showSelection ? 'radio' : '') +
                      ' ' +
                      (item[iconUrl] ? 'icon' : '') +
                      'list'
                    }
                    style={itemContainerStyle}
                    key={i}
                    onClick={() => handleSelection(item)}
                  >
                    {item[iconUrl] ? <img width='2.3rem' alt='' src={item[iconUrl]} /> : null}
                    <ModalContentText
                      className={(version === '1' ? '' : 'outside') + ' ' + (item.disable ? 'disable' : '')}
                      style={item[label] === activeItem[label] ? { color: '#AE0826', fontWeight: 'bold' } : TextStyle}
                    >
                      {item.title || item[label]}
                    </ModalContentText>
                  </ModalContentDiv>
                ))}
              </>
            </NestedModalContainer>
          </ModalContainer>
        )}
      </SelectDiv>
    </>
  );
}

export default DropDownModal;

export const IconSpan = styled.span``;
