export const fontSize = {
  large: '3rem',
  small: '0.6rem',
  medium: '1.4rem',
  text: '1.6rem',
  title: '1.8rem',
  titleBig: '2rem',
  ToastHeader: '2.7rem'
};
export const units = {
  revenueGrowth: '%',
  enterpriseToRevenue: 'x',
  grossMargins: '%',
  ebitdaMargins: '%'
};

export const sampleColumns = [
  {
    id: 1,
    label: 'Name',
    key: 'company',
    isSortable: true,
    align: 'left'
  },
  {
    id: 2,
    label: 'symbol',
    key: 'symbol',
    isSortable: true,
    align: 'right'
  },
  {
    id: 3,
    label: 'price',
    key: 'price',
    type: 'number',
    isSortable: true,
    align: 'right',
    unit: '$'
  },
  {
    id: 4,
    label: 'Horizontal/Vertical',
    key: 'isHorizontal',
    isTrue: 'Horizontal',
    isFalse: 'Vertical',
    isSortable: true
  },
  {
    id: 5,
    label: 'B2B/B2C',
    key: 'isB2B',
    isTrue: 'B2B',
    isFalse: 'B2C',
    isSortable: true
  },
  {
    id: 6,
    label: 'Preventive/Curative',
    key: 'isPreventive',
    isTrue: 'Preventive',
    isFalse: 'Curative',
    isSortable: true
  },
  {
    id: 8,
    label: 'Revenue growth rate',
    key: 'revenueGrowth',
    type: 'number',
    isSortable: true,
    unit: '%',
    align: 'right'
  },
  {
    id: 9,
    label: 'EBIDTA Margin',
    key: 'ebitdaMargins',
    type: 'number',
    isSortable: true,
    unit: '%',
    align: 'right'
  },
  {
    id: 10,
    label: 'Gross Margin',
    key: 'grossMargins',
    type: 'number',
    isSortable: true,
    unit: '%',
    align: 'right'
  },
  {
    id: 11,
    label: 'Total Revenue',
    key: 'totalRevenue',
    type: 'number',
    isSortable: true,
    unit: 'B',
    align: 'right'
  },
  {
    id: 12,
    label: 'Gross Profit',
    key: 'grossProfits',
    type: 'number',
    isSortable: true,
    unit: 'M',
    align: 'right'
  }
];

export const sampleData = [
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  },
  {
    name: 'adobe',
    symbol: 'ADBE',
    price: '$30.45',
    horizontalOrVertical: '+321094.324',
    b2bOrb2c: '32.4%',
    prevnOrCurat: '4.9X',
    'ev/fr': '8.65X',
    rgr: '10.3%',
    ebitaMargin: '12.9%',
    grossMargin: '39.4%'
  }
];
