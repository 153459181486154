import React, { useState } from 'react';
import TableComp from '../../Components/Table/Table.view';
import { FlexContainer } from '../../globalStyles';
import { CompaniesCap, Container, Heading, TableContainer, Text } from './Constituents.style';
import axios from 'axios';
import { endpoints } from '../../endpoints';
import { Duration, Span } from '../Overview/Overview.style';

const Constituents = () => {
  const [data, setData] = useState({});
  const [paginationDetails, setPaginationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const pageSize = 100;
  const marketCapFilters = [
    { id: 1, value: null, label: 'All' },
    { id: 2, value: 1, label: '$1B+' },
    { id: 3, value: 5, label: '$5B+' },
    { id: 4, value: 10, label: '$10B+' }
  ];
  const [activeMarketCapFilter, setActiveMarketCapFilter] = useState(marketCapFilters[0]?.value);

  const getData = async ({ pageNo, marketCap, sortBy, isAsc }) => {
    try {
      setLoading(true);
      setError();
      const endpoint = endpoints.constituents;
      const resp = await axios.post(endpoint, {
        pageNo,
        pageSize,
        marketCap: marketCap || marketCap === null ? marketCap : activeMarketCapFilter,
        sortBy,
        isAsc
      });
      if (resp.status >= 200 && resp.status < 300) {
        const {
          data: { data, count }
        } = resp;
        if (pageNo !== 1) {
          setData((prev) => ({ ...prev, ...data }));
        } else {
          setData(data);
        }
        setPaginationDetails({
          count,
          pageNo
        });
      } else {
        throw resp;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = ({ pageNoParam, sortBy, isAsc }) => {
    const { count, pageNo } = paginationDetails;
    if (pageNoParam !== undefined) {
      getData({
        pageNo: pageNoParam + 1,
        marketCap: activeMarketCapFilter,
        sortBy: sortBy || '',
        isAsc
      });
    } else if (!pageNo) {
      // init
      getData({
        pageNo: 1,
        marketCap: activeMarketCapFilter,
        sortBy: sortBy || '',
        isAsc
      });
    } else if (Object.entries(data).length < count) {
      // others
      getData({
        pageNo: pageNo + 1,
        marketCap: activeMarketCapFilter,
        sortBy: sortBy || '',
        isAsc
      });
    }
  };
  const handleMarketCapFilter = (value) => {
    const isChanged = activeMarketCapFilter !== value;
    if (isChanged) {
      setData({});
      setPaginationDetails({});
      setActiveMarketCapFilter(value);
      getData({ pageNo: 1, marketCap: value });
    }
  };
  return (
    <FlexContainer style={{ paddingBottom: '8rem' }} className='jc_center'>
      <Container>
        <CompaniesCap className='jc_spacebetween'>
          <Heading>All Companies</Heading>
          <Duration style={{ marginRight: '1rem', marginBottom: '1rem' }} className='constituents'>
            <Text className='label'>Market Cap:</Text>
            {marketCapFilters.map((cap, i) => {
              return (
                <Span
                  className={`active splitpoint  ${activeMarketCapFilter === cap.value && 'underline'}`}
                  onClick={() => handleMarketCapFilter(cap.value)}
                  key={cap.id}
                >
                  {cap.label}
                </Span>
              );
            })}
          </Duration>
        </CompaniesCap>
        <TableContainer style={{ overflowY: 'scroll' }}>
          <TableComp loading={loading} data={data} handleNext={handleNext} error={error} />
        </TableContainer>
      </Container>
    </FlexContainer>
  );
};

export default Constituents;
