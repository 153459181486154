import styled from 'styled-components';
import { fontFamily } from '../constants/font';

export const FlexContainer = styled.div`
  display: flex;
  &.col {
    flex-direction: column;
  }

  &.jc_center {
    justify-content: center;
  }

  &.jc_spacebetween {
    justify-content: space-between;
  }

  &.jc_spacearound {
    justify-content: space-around;
  }

  &.ai_center {
    align-items: center;
  }

  &.flex_wrap {
    flex-wrap: wrap;
  }
  &.icon {
    padding: 1rem;
    margin-right: 10px;
  }
  &.relative {
    position: relative;
  }
`;
export const Span = styled.span`
  font-size: 2.2rem;
  font-family: ${fontFamily.segoeUiSemiBold};
  color: #423b36;
  text-transform: capitalize;
  letter-spacing: 1px;

  &.companyDivisor {
    font-family: ${fontFamily.interBold};
    color: #707070;
    font-size: 1.8rem;
    margin-right: 1rem;
    marginleft: 1rem;
    text-transform: none;
    position: relative;
    right: 0.8rem;
  }
`;

export const Image = styled.img`
  transform: rotate(${({ rotateDeg }) => `${rotateDeg}deg`});
  &.rotate {
    transform: rotate(270deg);
  }
`;
