import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CartesianGrid, Legend, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import DropdownModal from '../../Components/Dropdown/DropdownModal';
import { fontFamily } from '../../constants/font';
import { endpoints } from '../../endpoints';
import { FlexContainer } from '../../globalStyles';
import useGraphWidthHeight from '../../shared/hooks/useGraphWidthHeight';
import { units } from '../../utility/constants';
import { errorHandler } from '../../utility/errorHandler';
import { DropDownLabel } from './Chart';
import { DropDownsWrap, Duration, OverviewChartDiv, Span, ToopltipP, Wrapper, YAxisLabel } from './Overview.style';
import { LoaderDiv } from '../Analysis/Analysis.style';
import Loader from '../../Components/Loader/Loader';

const filters = [
  { id: 1, label: '<30%', value: { max: 30 } }, //0
  { id: 2, label: '30%-50%', value: { min: 30, max: 50 } }, //1
  { id: 3, label: '>50%', value: { min: 50 } }, //2
  { id: 4, label: 'All', value: {} } //3
];

const dataForDropDown = [
  {
    id: 1,
    label: 'X-axis',
    key: 'xAxis',
    data: [
      { label: 'EBIDTA %', xValue: 'ebitdaMargins', tickCount: 15, interval: 2 },
      { label: 'Gross Margin', xValue: 'grossMargins', tickCount: 15, interval: 2 },
      { label: 'Revenue Growth Rate', xValue: 'revenueGrowth', tickCount: 15, interval: 2 }
    ]
  },
  {
    id: 2,
    label: 'Y-axis',
    key: 'yAxis',
    data: [{ label: 'Enterprise Value / Revenue', yValue: 'enterpriseToRevenue' }]
  }
];

const fitlerFor = 'revenueGrowth';
const KeyCloudIndexMetric = () => {
  const [growthRateFilter, setGrowthRateFilter] = useState(filters[3]);
  const [completeData, setCompleteData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(false);
  const chartBoxRef = useRef();
  const { widthOf, heightOf } = useGraphWidthHeight(chartBoxRef);
  const [dropdownVal, setDropdownVal] = useState({});
  const { xValue, label: xLabel } = dropdownVal?.xAxis || dataForDropDown[0].data[0];
  const { yValue, label: yLabel } = dropdownVal?.yAxis || dataForDropDown[1].data[0];
  const filterData = (data, option, xValueParam, yValueParam) => {
    const {
      value: { min, max }
    } = option;
    const res = data.filter((e) => {
      let filterCondition = true;
      if (min && !max) {
        // min defined but max not defined
        filterCondition = e?.[fitlerFor] > min;
      } else if (!min && max) {
        // max defined but min not defined
        filterCondition = e?.[fitlerFor] <= max;
      } else if (min && max) {
        // max defined but min not defined
        filterCondition = e?.[fitlerFor] <= max && e?.[fitlerFor] >= min;
      }
      return e?.[yValueParam || yValue] !== '' && e?.[xValueParam || xValue] !== '' && filterCondition;
    });
    return res;
  };

  const handleFilter = (option) => {
    setGrowthRateFilter(option);
    setPlotData(filterData(completeData, option));
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post(endpoints.constituents)
      .then((res) => {
        setLoading(false);
        const { data, status } = res;
        if (status >= 200 && status < 300) {
          const finalData = Object.values(data.data);
          setCompleteData(finalData);
          setPlotData(filterData(finalData, growthRateFilter));
        } else {
          throw res;
        }
      })
      .catch((e) => {
        setLoading(false);
        const error = errorHandler(e);
        console.log('e', e, error.error);
      });
  }, []);
  const handleDropdown = (item, property) => {
    setDropdownVal((prev) => ({
      ...prev,
      [property]: item
    }));

    const xAxis = property === 'xAxis' ? item.xValue : '';
    const yAxis = property === 'yAxis' ? item.yValue : '';
    setPlotData(filterData(completeData, growthRateFilter, xAxis, yAxis));
  };
  const res = useMemo(() => {
    return plotData;
  }, [plotData, yValue]);
  return (
    <>
      <Duration className='keyIndexChart'>
        <FlexContainer style={{ gap: '4rem' }}>
          <OptionsLabel> YoY Growth Rate:-</OptionsLabel>
          {filters.map((ele, i) => (
            <Span
              className={`active splitpoint  ${growthRateFilter?.label === ele.label && 'underline'}`}
              onClick={() => handleFilter(filters[i])}
              key={ele.id}
            >
              {ele.label}
            </Span>
          ))}
        </FlexContainer>
        <Wrapper className='keyCloudIndex'>
          {dataForDropDown &&
            dataForDropDown?.map((e, i) => {
              const isXaxis = e.key === 'xAxis';
              return (
                <DropDownsWrap key={e.id}>
                  <DropDownLabel style={{ textTransform: 'capitalize' }}>{e.label}</DropDownLabel>
                  <DropdownModal
                    data={e.data}
                    header={isXaxis ? xLabel : yLabel}
                    property={e.key}
                    setDropdownVal={handleDropdown}
                    width={e.key === 'yAxis' ? '37rem' : '29rem'}
                  />
                </DropDownsWrap>
              );
            })}
        </Wrapper>
      </Duration>
      <OverviewChartDiv className='normal-size' ref={chartBoxRef} style={{ height: 'auto' }}>
        {loading && (
          <LoaderDiv>
            <Loader />
          </LoaderDiv>
        )}
        <YAxisLabel>{yLabel}</YAxisLabel>
        <ScatterChart
          width={widthOf}
          height={widthOf > 768 ? heightOf + 30 : heightOf}
          margin={{
            top: 20,
            right: 20,
            bottom: 10,
            left: 10
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={xValue}
            type='number'
            name={xLabel}
            label={xLabel}
            tickFormatter={(p) => `${Number(p).toFixed(0)}${units[xValue]}`}
            domain={['auto', 'auto']}
          />
          <YAxis
            dataKey={yValue}
            type='number'
            tickFormatter={(p) => `${Number(p).toFixed(0)}${units[yValue]}`}
            name={yLabel}
            label={yLabel}
            scale='sqrt'
          />
          <Tooltip
            wrapperStyle={{
              outline: 'none',
              background: '#ffffff 0% 0% no-repeat padding-box',
              boxShadow: '0px 0px 25px #00000026',
              border: '0.5px solid #a0a0a0',
              padding: '5px 5px',
              minWidth: 180
            }}
            content={<CustomTooltip keyCloudIndexMetic={true} />}
          />
          <Legend />
          <Scatter name={xLabel} data={res} fill='#8884d8' />
        </ScatterChart>
      </OverviewChartDiv>
    </>
  );
};

// TODO: include company to tooltip
export default KeyCloudIndexMetric;

export const OptionsLabel = styled.span`
  font-size: 2.2rem;
  font-family: ${fontFamily.interBold};
  letter-spacing: 2.2px;
  color: #707070;
  margin-right: 1rem;
`;

function CustomTooltip({ payload, label, active, keyCloudIndexMetic }) {
  if (active) {
    return (
      <div className='custom-tooltip' style={{ padding: '1rem' }}>
        {keyCloudIndexMetic && (
          <ToopltipP style={{ color: '#AE0826' }} className='labelName'>
            {payload?.[0].payload?.company}
          </ToopltipP>
        )}

        {payload?.map((ele) => {
          return (
            <FlexContainer key={ele.name} FlexContainer style={{ justifyContent: 'space-between' }}>
              <ToopltipP className='labelName'>{ele.name}</ToopltipP>
              <span style={{ width: '4rem' }}>&nbsp;</span>
              <ToopltipP style={{ color: 'black' }} className='labelName'>
                {`${Number(ele.value).toFixed(2)}${units[ele.dataKey] || ''}`}
              </ToopltipP>
            </FlexContainer>
          );
        })}
      </div>
    );
  }

  return null;
}
